const recogPhotos = [
    'ASES.png',
    'startup growth.jpg',
    'LES.jpg',
    'qlitan.jpg',
    'hustleshare banner.png',
    'hustleshare.png',
    'PHSW.png',
    'GDSC.jpg',
    'devcon.png'
]

export default recogPhotos
